import * as msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
      clientId: "6fc2b1b2-8181-4502-8c37-3cffd57f1db2",
      authority:
          `https://login.microsoftonline.com/79dc228f-c8f2-4016-8bf0-b990b6c72e98`,
      redirectUri: '/',
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };