import Button from "@material-ui/core/Button";
import React from 'react';
import { useMsal } from '@azure/msal-react';
import {msalInstance} from "../services/msal"

export  function SignInButton({ loginHint }) {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  

  return <Button
    onClick={() => msalInstance.loginRedirect({
      scopes: ["user.read"],
      loginHint
    })}> Sign in {loginHint ? " as " + loginHint : ""}</Button>;
}
