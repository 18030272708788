import { useEffect, useState } from 'react'
import React from 'react'
import cx from 'clsx';
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import MailIcon from "@material-ui/icons/MailTwoTone";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import useSWR from "swr"
import axios from "axios"
import localdata from "./org.json";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import "./App.css"

var ORGURL = window.location.search.substring(1) // "https://netszbar.blob.core.windows.net/hexatown/organisation/2021-12-09-json?sp=r&st=2021-12-09T19:50:21Z&se=2099-12-10T03:50:21Z&spr=https&sv=2020-08-04&sr=b&sig=5DbuJAWw6StvYWF4kpzwyBYn6Tk3mvNv9%2BfdENwdaB0%3D"

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#ECECF4"
  }
}));

function Organization({ org, onCollapse, collapsed }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "account",
    drop: () => ({ name: org.tradingName }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  if (isActive) {
    backgroundColor = "#ddffd2";
  } else if (canDrop) {
    backgroundColor = "#ffeedc";
  }
  return (
    <Card
      variant="outlined"

      className={classes.root}
      ref={drop}
      style={{ backgroundColor, minWidth: "200px" }}
    >
      <CardHeader
        avatar={
          <Tooltip
            title={`${_.size(
              org.organizationChildRelationship
            )} Sub Profile, ${_.size(org.account)} Sub Account`}
            arrow
          >
            <Badge
              style={{ cursor: "pointer" }}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              showZero
              invisible={true /*!collapsed || _.size(org.organizationChildRelationship)<1*/}
              overlap="circle"
              badgeContent={_.size(org.organizationChildRelationship)}
              onClick={onCollapse}
            >
              {/* <Avatar className={classes.avatar}>
                <BusinessIcon color="primary" />
              </Avatar> */}
            </Badge>
          </Tooltip>
        }
        title={org.tradingName}
      // action={
      //   <IconButton size="small" onClick={handleClick}>
      //     <MoreVertIcon />
      //   </IconButton>
      // }
      />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom component="div">
          {org.manager}
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div">


          <Box sx={{ typography: 'body2' , fontSize: 10 }}>
           {org.counters ? org.counters.internal  : ""}|{org.counters ? org.counters.external  : ""}|{org.counters ? org.counters.temporary  : ""}
           </Box>
          
        </Typography>


      </CardContent>

      {/* <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <BusinessIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Add Sub Profile" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBalanceIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Add Sub Account" />
        </MenuItem>
      </Menu> */}
      <IconButton
        size="small"
        onClick={onCollapse}
        className={clsx(classes.expand, {
          [classes.expandOpen]: !collapsed
        })}
      >
        {_.size(org.organizationChildRelationship) > 0 &&
          <ExpandMoreIcon />}
      </IconButton>
    </Card>
  );
}
function Account({ a }) {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag({
    item: { name: a.name, type: "account" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        alert(`You moved ${item.name} to ${dropResult.name}`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <Card
      variant="outlined"
      className={classes.root}
      ref={drag}
      style={{ cursor: "pointer", opacity }}
    >
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <AccountBalanceIcon color="secondary" />
          </Avatar>
        }
        title={a.name}
      />
    </Card>
  );
}
function Product({ p }) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <Typography variant="subtitle2">{p.name}</Typography>
      </CardContent>
    </Card>
  );
}
function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });
  const T = parent
    ? TreeNode
    : (props) => (
      <Tree
        {...props}
        lineWidth={"2px"}
        lineColor={"#bbc"}
        lineBorderRadius={"12px"}
      >
        {props.children}
      </Tree>
    );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o.account, (a) => (
        <TreeNode label={<Account a={a} />}></TreeNode>
      ))}
      {_.map(o.organizationChildRelationship, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}
const theme = createMuiTheme({
  palette: {
    background: "#ECECF4"
  },
  fontFamily: "Roboto, sans-serif"
});



export default function App(props) {


  const [error, setError] = useState(null);
  const [organization, setOrganization] = useState(localdata);


  // useEffect(() => {

  //   axios.get(ORGURL).then(result => {

  //     setOrganization(result.data)}).catch(e => setError(e.message))


  // });

  if (error) return <div><div>failed to load {error.message}
  </div>
    <div>
      {ORGURL}
    </div>
  </div>
  if (!organization) return <div>loading...</div>
  return (
    <ThemeProvider theme={theme}>
      <Box padding={4}>
        <DndProvider backend={HTML5Backend}>
          <div>


            {organization &&
              <Node o={organization} />
            }

          </div>
        </DndProvider>
      </Box>
    </ThemeProvider>
  );
}
