import ReactDOM from "react-dom";
import App from "./pages/App";
import React from 'react'
import * as msal from '@azure/msal-browser';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import { SignInButton } from "./components/SignInButton";

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './services/msal'







function Home() {
    return (
        <div>
<MsalProvider instance={msalInstance}>
            <UnauthenticatedTemplate>
<SignInButton hint=""/>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <App />
            </AuthenticatedTemplate>
            </MsalProvider>
        </div>
    )
}


const rootElement = document.getElementById("root");
ReactDOM.render(<Home />, rootElement);
